import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import map from 'lodash/map';

import { CouponStatus, GetCheckoutSummaryQuery as GetCheckoutSummary } from '__generated__/graphql';

import { ErrorBag } from '../../../common/helpers';
import { NestedTouchState } from '../../../common/useTouchState';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';

export interface CouponInputProps {
  value?: string | null;
  onChange: (value: string) => void;
  onBlur?: () => void;
  checkoutSummary: GetCheckoutSummary['checkoutSummary'];
  loading?: boolean;
  errors?: ErrorBag;
  touched?: NestedTouchState;
}

const CouponInput = ({ value, onChange, onBlur, checkoutSummary, loading, errors = {}, touched }: CouponInputProps) => {
  // Explicitly use frontend namespace, because this component is also used in the participant dashboard
  const { t } = useTranslation('frontend');
  const { formatCurrency, formatNumber } = useLocale();

  // The coupon code that has been applied to any of the purchases.
  const couponCode: GetCheckoutSummary['checkoutSummary']['coupon_code'] = filter(
    map([...checkoutSummary.tickets, ...checkoutSummary.products], 'purchase.coupon_code'),
  )[0];

  // The coupon status that is set even when the coupon has not been applied.
  const couponStatus = checkoutSummary.coupon_status;

  return (
    <UI.FormGrid sc={{ gutter: 0.5 }}>
      <UI.InputGroup sc={{ valid: !errors?.coupon, touched: !!touched?.coupon, mb: 0 }}>
        <UI.InputLabel htmlFor="CouponCode">
          {t('payment_form.coupon_code')}
        </UI.InputLabel>
        <UI.DebouncedInput
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onBlur={() => onBlur?.()}
          delay={500}
          id="CouponCode"
        />
      </UI.InputGroup>
      {couponCode && (
        <UI.FadeIn>
          <UI.Success>
            {t('payment_form.discount')}
            {': '}
            {couponCode.coupon.type === 'absolute' && formatCurrency(couponCode.coupon.value)}
            {couponCode.coupon.type === 'percentage' && `${formatNumber(couponCode.coupon.value)}%`}
          </UI.Success>
        </UI.FadeIn>
      )}
      {!couponCode && value && couponStatus && !loading && (
        <UI.FadeIn>
          <UI.InputGroup sc={{ color: 'error' }}>
            {couponStatus === CouponStatus.active && t('coupon_statuses.active_not_applied')}
            {couponStatus !== CouponStatus.active && t(`coupon_statuses.${couponStatus}`)}
          </UI.InputGroup>
        </UI.FadeIn>
      )}
    </UI.FormGrid>
  );
};

export default CouponInput;
