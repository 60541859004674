import { gql } from '__generated__';

export default gql(`
  query GetEvent($id: ID!, $invitationCode: String) {
    event(id: $id) {
      id
      project {
        id
        organisation_name
        organisation_email
        organisation_address
        organisation_zip_code
        organisation_city
        organisation_country
        shift_vat
        locale
        timezone
        privacy_statement
        google_analytics
        facebook_pixel
        genders
      }
      locale
      brand_color
      title
      description
      email
      website_url
      location {
        id
        title
        description
      }
      logo {
        id
        url
      }
      terms {
        id
        url
      }
      payment_methods
      require_company_for_bank_transfer
      terms_url
      terms_text
      confirmation_text
      participant_attributes
      start_date
      end_date
      enable_fundraising
      require_fundraising
      fundraising_mode
      supporta_url
      allow_other_charity
      charities {
        id
        title
        logo_url
        description
      }
      random_charities {
        id
        title
        logo_url
      }
      charities_count
      invitation_code(invitation_code: $invitationCode) {
        id
        availability
        value
        invitation {
          id
          description
          team {
            id
            title
          }
        }
      }
      ticket_categories(invitation_code: $invitationCode) {
        id
        title
        start_date
        sport_type
        current_max_per_order
        formatted_distances
        tickets_for_sale {
          id
          title
          description
          assignment_type
          image {
            id
            url
          }
          current_price_range
          min_per_order
          current_max_per_order
          current_discount {
            id
            title
            type
            levels {
              id
              quantity
              value
            }
          }
          accept_down_payments
          promotions_for_sale {
            ...GetEvent_PromotionFields
          }
          upcoming_time_slots {
            id
            start_date
            start_time
            title
            multi_date
            is_sold_out
            current_max_per_order
          }
          units
          business
          allow_individuals
          allow_create_team
          allow_join_team
          restrictions {
            id
            gender
            min_age
            max_age
          }
          is_sold_out
          charity {
            id
            title
            logo_url
            description
          }
        }
      }
      products_for_sale {
        id
        title
        description
        image {
          id
          url
        }
        current_price_range
        min_per_order
        current_max_per_order
        is_sold_out
        current_discount {
          id
          title
          type
          levels {
            id
            quantity
            value
          }
        }
        donation
        minimum_donation_amount
        accept_down_payments
        stand_alone
        promotions_for_sale {
          ...GetEvent_PromotionFields
        }
        active_product_variants {
          id
          title
          is_sold_out
          current_max_per_order
        }
        min_per_ticket
        max_per_ticket
        min_per_order
        max_per_order
        is_ticket_fee
        tickets {
          id
        }
        products {
          id
        }
      }
      enabled_participant_fields(invitation_code: $invitationCode) {
        id
        title
        description
        scope
        type
        enabled_choices {
          id
          title
          position
          enabled
        }
        required
        required_promotions {
          id
        }
        required_participant_attributes
        config
        has_external_validation
      }
      explicit_mail_permissions {
        id
        label
      }
      accept_down_payments
      down_payment_deadline
      down_payment_percentage
      has_invitation_codes
      checkout_url
      active
      enable_public_resale
      resale_url
      registrations_for_sale_count
    }
  }

  fragment GetEvent_PromotionFields on Promotion {
    id
    title
    amount
    current_max_per_order
    standard
    is_sold_out
  }
`);
